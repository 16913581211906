@import './../../variables.scss';

.create_pool {
    padding-bottom: 40px !important;

    .acknwo_wrap {
        margin-top: 40px;
        width: 100%;
        counter-reset: ack-counter;

        .ackn_item {
            padding-left: 50px;
            position: relative;
            padding-bottom: 30px;

            &.active {
                &:before {
                    background-color: #5a84fc;
                    color: #fff;
                }

                .details {
                    max-height: 350px;
                }
            }

            &.passed {
                &:before {
                    content: '✓';
                    background-color: #5a84fc;
                    color: #fff;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &:before {
                @include pseudo_abs();
                counter-increment: ack-counter;
                content: counter(ack-counter);
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color: #fff;
                @include flex-row();
                line-height: 1;
                background-color: #fff;
                color: #000;
            }

            &:after {
                @include pseudo_abs();
                height: calc(100% - 45px);
                width: 2px;
                background-color: #fff;
                left: 15px;
                top: 35px;
            }

            .title {
                font-size: 16px;
                line-height: 17px;
                color: var(--dark-text-x1);
                padding-top: 5px;
            }

            .details {
                max-height: 0px;
                overflow: hidden;
                transition: max-height 0.5s;

                .buttons_wrap {
                    button {
                        margin-right: 15px;
                    }
                }
            }

            
        }
    }

    .button_tab_wrap {
        margin-top: 40px;
        button {
            border-radius: 0;
            padding-left: 30px;
            padding-right: 30px;

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:hover {
                &:active {
                    transform: none;
                }
            }
        }
    }

    .processing_box {
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
            margin-top: -12%;
        }

        .head {
            font-size: 20px;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin-bottom: 20px;
    display: inline-block;
}

@media only screen and (max-width: $breakpoint-sm) {
    html {
        body {
            .create_pool {
                padding-bottom: 30px !important;

                .buttons_wrap {
                    @include flex-row();

                    button {
                        flex: 1;
                    }
                }

                .button_tab_wrap {
                    width: 100%;
                    @include flex-row();
                }

                .processing_box {
                    .head {
                        font-size: 19px;
                    }
                }
            }
        }
    }
}
