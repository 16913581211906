.modal-1 {
    transition: opacity 0.1s ease-in-out 0s;
    text-align: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin-left: -50vw;
    top: 0px;
    left: 50%;
    z-index: 2000;
    will-change: opacity;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    visibility: visible;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.modal-2 {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    z-index: 10 !important;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    opacity: 2001;
    visibility: visible;
}

.modal-card {
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    margin: 10px;
    padding: 0px;
    opacity: 1;
    z-index: 100 !important;
    visibility: visible;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    max-width: 500px;
    min-width: fit-content;
    max-height: 100%;
    overflow: auto;
}