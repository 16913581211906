@import './variables.scss';

button {
    --button-bg: #5a84fc;
    --button-text: #fff;
    --button-border: #5a84fc;

    &.invert {
        --button-bg: var(--input-bg-color);
        --button-text: #5a84fc;
    }

    &.base_1 {
        --button-bg: #4273fd;
        --button-border: #4273fd;

        &.invert {
            --button-text: #4273fd;
        }
    }

    &.base_2 {
        --button-bg: #2c5ff1;
        --button-border: #2c5ff1;

        &.invert {
            --button-text: #2c5ff1;
        }
    }

    &.disabled {
        --button-bg: #313149;
        --button-border: #2b2b42;
        --button-text: rgba(43, 43, 66, 1);
        cursor: default !important;
        transition: none !important;

        &.invert {
            --button-bg: var(--input-bg-color);
            --button-border: var(--border-color-dark-only);
            --button-text: rgba(228, 228, 251, 1);
        }

        &:hover {
            &:active {
                transform: scale(1);
            }
        }
    }

    &:hover {
        &:active {
            transform: scale(0.98);
        }
    }

    transition: transform 0.4s;
    background: var(--button-bg);
    border-radius: 6px;
    border: 2px solid var(--button-border);
    color: var(--button-text);
    padding: 8px 20px;
    cursor: pointer;
    transition: background 0.4s, color 0.6s;
}

input[type='checkbox'],
input[type='radio'] {
    --active: #275efe;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
            opacity var(--d-o, 0.2s);
    }
    &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
        &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
        }
        & + label {
            cursor: not-allowed;
        }
    }
    &:hover {
        &:not(:checked) {
            &:not(:disabled) {
                --bc: var(--border-hover);
            }
        }
    }
    &:focus {
        box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
        width: 21px;
        &:after {
            opacity: var(--o, 0);
        }
        &:checked {
            --o: 1;
        }
    }
    & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
    }
}

input[type='checkbox'] {
    &:not(.switch) {
        border-radius: 7px;
        &:after {
            width: 5px;
            height: 9px;
            border: 2px solid var(--active-inner);
            border-top: 0;
            border-left: 0;
            left: 7px;
            top: 4px;
            transform: rotate(var(--r, 20deg));
        }
        &:checked {
            --r: 43deg;
        }
    }
    &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
            left: 2px;
            top: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background: var(--ab, var(--border));
            transform: translateX(var(--x, 0));
        }
        &:checked {
            --ab: var(--active-inner);
            --x: 17px;
        }
        &:disabled {
            &:not(:checked) {
                &:after {
                    opacity: 0.6;
                }
            }
        }
    }
}

.input_box {
    min-width: 200px;
    font-size: 14px;
    border-radius: 8px;
    padding: 8px 15px;
    border: 0px;
    outline: none;
    background-color: var(--input-bg-color);
    color: var(--dark-text) !important;
    transition: background 0.4s, color 0.6s;
    border: 1px solid var(--border-color);
}

.select_wrap {
    position: relative;

    &:before {
        @include pseudo_abs();
        width: 15px;
        height: 100%;
        z-index: 2;
        right: 0;
        top: 0;
        left: auto;
        background-color: var(--input-bg-color);
        border-radius: 8px;
        transition: background 0.4s, color 0.6s;
    }

    &:after {
        @include pseudo_abs();
        content: '▼';
        width: 15px;
        height: 100%;
        z-index: 2;
        right: 5px;
        top: 8px;
        left: auto;
        border-radius: 8px;
        font-size: 12px;
        color: var(--dark-text) !important;
        transition: background 0.4s, color 0.6s;
    }

    .item {
        color: var(--dark-text-x1);
        transition: color 0.4s;
    }
    select {
        font-weight: 600;
    }
}
