@import './../../variables.scss';

.index_funds {
    position: relative;

    h1 {
        margin-bottom: 20px !important;
    }

    .abs_right {
        @include flex-row();

        select {
            margin-left: 15px;
        }
    }
}
